body {
  background-color: #0d1116;
  background: #212a35;
  color: #ffffff;
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
}

.portfolio-container {
  background: linear-gradient(135deg, #34495e, #2c3e50);
  padding: 2rem;
  margin: 2rem auto;
  border-radius: 5vh;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  max-width: 70%;
  text-align: left;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  position: relative;
}

.portfolio-container:hover {
  transform: translateY(-3px);
  box-shadow: 0 7px 20px rgba(0, 0, 0, 0.3);
}

.authors-container-title {
  color: #fce588;
  font-size: 1.7rem;
  font-weight: bold;
  text-shadow: none;
  margin-bottom: 1.2rem;
}

.authors-container-text {
  font-size: 1rem;
  line-height: 1.8;
  margin-bottom: 1rem;
  color: #ddd;
}

.authors-container-text strong {
  color: #34D399;
  font-weight: 500;
}

ul {
  list-style-type: disc;
  margin-left: 2rem;
  color: #ddd;
  padding-left: 10px;
}

ul li {
  margin-bottom: 0.5rem;
}

.back-link {
  display: inline-block;
  margin-top: 2rem;
  padding: 10px 20px;
  text-decoration: none;
  font-size: 1rem;
  color: white;
  background: linear-gradient(135deg, #66cc99, #3cb371);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
  font-weight: bold;
  transition: background-color 0.2s ease-in-out, transform 0.1s ease-in-out;
  cursor: pointer;
}

.back-link:hover {
  background: linear-gradient(135deg, #59c7f0, #4facf2);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

h1,
h2,
h3 {
  margin-bottom: 0.8rem;
}

h1 {
  font-size: 2rem;
  color: #fce588;
  text-shadow: none;
}

h2 {
  font-size: 1.5rem;
  color: #add8e6;
  text-shadow: none;
}

h3 {
  font-size: 1.2rem;
  color: #a2facf;
  font-weight: 500;
}

a {
  color: #3498db;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

a:hover {
  color: #217dbb;
  text-decoration: underline;
}

strong {
  font-weight: 600;
  margin-right: 5px;
}

a + a {
  margin-left: 10px;
}

/* Profile image Styles */
.profile-image-container {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Game Styles */
.game-container {
display: flex;
flex-direction: column;
align-items: center;
margin-bottom: 2rem;
background: linear-gradient(135deg, #273746, #1e2d3b); /* Slightly darker background */
border-radius: 10px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
padding: 1.5rem;
}


.game-description {
text-align: center;
margin-bottom: 1rem;
font-style: italic; /* Optional: italicize game description */
}

.game-iframe {
width: 100%;
max-width: 600px; /* Adjust max width as needed */
height: 350px; /* Adjust height as needed */
border: none;
margin: 0 auto; /* Center the iframe */
}
@keyframes gradient-animation {
0% {
  background-position: 0% 50%;
}
50% {
  background-position: 100% 50%;
}
100% {
  background-position: 0% 50%;
}
}