.heading {
    font-size: 3rem; /* Reduced size, more reasonable*/
    font-weight: bold;
    margin: 2vw auto 0vw; /* Updated margins*/
    letter-spacing: -0.5px; /* Slightly reduced letter-spacing*/
}

.gradient-text {
    background: linear-gradient(-70deg, #a2facf, #d1ff65); /*  Use Linear Gradient for cleaner look*/
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(4px 2px 2px rgba(0, 0, 0, 1)); /* More refined shadow */
    word-wrap: break-word;
    display: inline-block; /* ensure proper wrap with display: inline-block*/
}

header .subheading {
    font-size: 0.9rem;
    font-weight: 300;
    word-wrap: break-word;
}

.subheading a {
    color: #add8e6; /* light blue color*/
    font-weight: 500; /* Reduced weight, not too bold */
    text-decoration: none;
}

.subheading a:hover {
    text-decoration: underline;
}

.os-button {
    margin: 15px auto; /* consistent margin */
    padding: 10px 20px; /* consistent padding*/
    color: white;
    background-color: #3498db; /* Consistent color */
    border: none;
    border-radius: 8px; /* Consistent radius */
    font-size: 1rem; /* Use rem */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background-color 0.2s ease-in-out, transform 0.1s ease-in-out;
    cursor: pointer;
    display: inline-block;
}

.os-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}


.os-button a {
    color: white;
    text-decoration: none;
}