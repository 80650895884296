.footer {
    padding: 20px 0; /* Reduced padding for spacing */
    margin-top: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.1); /* Separator line */
}

.footer p {
    font-size: 0.9rem; /* Reduced size for balance */
    text-align: center;
    line-height: 1.7; /* Adjusted line height */
    color: #aaa;
    margin-bottom: 5px; /* added space for bottom */
}

.footer span {
    background: linear-gradient(-70deg, #a2facf, #d1ff65); /* Standard linear-gradient*/
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.footer-social-media-links {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.footer-social-media-links > div {
    margin: 0 10px; /* Adjusted horizontal margin*/
}

.contract-link {
    text-decoration: underline;
    color: #add8e6; /* light blue link */
    text-decoration-color: #a2facf;
    word-wrap: break-word;
    font-weight: 500;
}

.contract-link:hover {
    text-decoration: none;
}

.social-icon {
    width: 25px;
    height: 25px;
    fill: currentColor;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
}

.social-icon:hover {
    transform: scale(1.2);
}