body {
  background-color: #0d1116;
  background: #212a35;
  color: #ffffff;
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

.metamask-error {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fce588;
  color: #333;
  width: 100%;
  padding: 1.2vh 0;
  text-align: center;
  z-index: 100;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
}

.App {
  text-align: center;
  padding: 0;
}

.container {
  background: linear-gradient(135deg, #34495e, #2c3e50);
  padding: 4vh 4vw;
  max-width: 70%; /*  Adjusted max width */
  margin: 6vh 6vw;
  border-radius: 10vh;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  display: inline-block; /* Make it a dynamic inline block */
}


.container:hover {
  transform: translateY(-3px);
  box-shadow: 0 7px 20px rgba(0, 0, 0, 0.3);
}

.guide {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.6;
  text-align: center; /* Center the content of the guide class */
}

.guide a {
  color: #add8e6;
  text-decoration: none;
}

.guide a:hover {
  text-decoration: underline;
}

.guide p {
  font-size: 0.9rem;
  margin-bottom: 12px;
  text-align: center; /* Center all paragraph text */
  white-space: normal; /* Ensure normal whitespace behavior */
  word-spacing: normal; /* Ensure normal word spacing */
}

.banner-img {
  margin-bottom: 15px;
  padding: 0 1vw;
}

.banner-img img {
  margin: auto;
  max-height: 300px;
  max-width: 100%;
  border-radius: 10px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
  display: block;
}

.header-container {
  padding-top: 15px;
}

.cta-button {
  border: none;
  margin: 10px auto;
  padding: 8px 12px;
  color: #fff;
  width: auto;
  min-width: 120px;
  height: auto;
  text-shadow: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 600;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s ease-in-out, transform 0.1s ease-in-out;
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.connect-wallet-button {
  background: linear-gradient(135deg, #59c7f0, #4facf2);
}


.mint-button {
  background: linear-gradient(135deg, #66cc99, #3cb371);
}

.mintCounter {
  font-weight: 600;
  font-size: 0.8rem;
  margin-top: 8px;
  text-shadow: none;
}

.progress-bar {
  color: #fce588;
  margin-top: 3px;
  width: 80%;
  margin: 3px auto;
}

.opensea-button {
  background-color: #3498db;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.footer-container {
display: flex;
justify-content: center;
align-items: center;
margin-top: 20px;
  padding-bottom: 10px;
}

.footer-text {
color: #aaa;
font-size: 0.7rem;
}

/* Counter Buttons */

.counter {
  padding: 6px 8px;
  position: relative;
  border: none;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 600;
  margin: 0px 6px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: #fff;
  text-shadow: none;
  transition: transform 0.1s ease-in-out;
}

.counter:hover {
  transform: scale(1.05);
}


.increment-button {
  background: linear-gradient(135deg, #66cc99, #3cb371);
}

.decrement-button {
  background: linear-gradient(135deg, #ff7f50, #d6604d);
}

.counter_display {
  text-align: center;
  font-size: 1rem;
  text-shadow: none;
  margin-left: 3px;
  font-weight: 600;
}

.mining {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
}

.mining span, .mining div {
  display: inline-block;
}

.mining span {
  color: #fce588;
  font-size: 1rem;
  font-weight: 600;
}

.success, .mining {
  margin-bottom: 10px;
}

.success button {
  display: inline-block;
  margin: 8px auto;
  text-align: center;
  background: linear-gradient(135deg, #66cc99, #3cb371);
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  color: #fff;
}

.success p {
  color: #34D399;
  font-size: 0.9rem;
  text-shadow: none;
}

.error p {
  color: #ff5252;
  font-size: 0.9rem;
  text-shadow: none;
}

.minting-finished p {
  color: #ff5252;
  font-size: 0.9rem;
  text-shadow: none;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #fce588;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: spin 1s linear infinite;
  margin: 0 8px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.success-link {
  line-height: 1.4;
  margin-top: 5px;
}

.success-link span {
  color: #ddd;
}

.success-link a {
  color: #fff;
  font-weight: 600;
  text-decoration: none;
}

.success-link a:hover {
  text-decoration: underline;
}

/* For Round Button*/
.e-plus-icon::before {
  content: '\e823';
}

button {
  margin: 8px 8px;
}

#loader {
  color: #008cff;
  height: 40px;
  left: 45%;
  position: absolute;
  top: 45%;
  width: 30%;
}


/* NEW STYLES */
.header-and-connect {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.connect-wallet-container {
  display: flex;
  justify-content: flex-end;
}

/* Responsive design */

@media (max-width: 768px) {
.container {
   padding: 3vh 3vw;
   max-width: 95%;
}

.cta-button {
  font-size: 0.8rem;
  min-width: 100px;
}

.mintCounter {
    font-size: 0.7rem;
}

.counter {
      padding: 5px 6px;
      width: 30px;
    height: 30px;
      font-size: 0.8rem;
      margin: 0px 5px;
  }
  .counter_display{
    font-size: 0.9rem;
  }
}

.mint-controls-container {
  display: flex;
  flex-direction: column; /* Stack the counter controls and mint button */
  align-items: center;
  padding-top: 10px;
  margin: 10px auto;
}

.counter-controls {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the counter controls horizontally */
}

.mint-counter-container {
 margin: 5px auto;
 text-align: center;
}